import React from 'react';
import { setProfile } from '~/services/security.service';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';

/* useEffect(() => {
  if (success) {
    handleEscape();
    setTimeout(() => {
      setProfile({
        name: name,
        username: user.username,
        email: user.email,
        profession: profileProfession,
        entityType: profileType
      })
        .then(() => {
          // onChange({ profession: profileProfession, entityType: profileType, name: user.name });
          dialogRef.current && dialogRef.current.hide();
          const url = new URL(window.location.href);
          url.searchParams.delete('signupSurvey');
          window.history.replaceState(null, null, url);
        })
        .catch((e) => {
          console.log('error', e);
        });
    }, 1000);
  } */

const useSetProfile = (profile, setProfile, dialogRef, handleEscape) => {
  const { success } = useOnboardingStore();
  return React.useEffect(() => {
    if (success) {
      handleEscape();
      setTimeout(() => {
        setProfile({
          ...profile
        })
          .then(() => {
            // onChange({ profession: profileProfession, entityType: profileType, name: user.name });
            dialogRef.current && dialogRef.current.hide();
            const url = new URL(window.location.href);
            url.searchParams.delete('signupSurvey');
            window.history.replaceState(null, null, url);
          })
          .catch((e) => {
            console.log('error', e);
          });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);
};

export default useSetProfile;
