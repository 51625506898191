import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  backButtonContainer: {
    width: 'fit-content',
    height: 'auto',
    display: 'flex',
    position: 'absolute',
    top: '0',
    left: '0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '50px'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    overflow: 'hidden',
    width: '50%',
    padding: theme.spacing(2),
    '&.mobile': {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(4)
    }
  },
  pictureContainer: {
    flex: 1,
    overflow: 'hidden',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  picture: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: theme.spacing(1)
  },
  alert: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    '&.success': {
      '& .MuiAlert-icon': {
        color: '#4caf50',
        '& .MuiAlert-iconOutline': {
          fill: '#4caf50'
        },
        '& .MuiAlert-iconFilled': {
          fill: '#4caf50'
        }
      },
      '& .MuiAlert-message': {
        color: '#4caf50'
      },
      '& .MuiAlert-action': {
        color: '#4caf50'
      }
    },
    '&.error': {
      '& .MuiAlert-icon': {
        color: '#f44336',
        '& .MuiAlert-iconOutline': {
          fill: '#f44336'
        },
        '& .MuiAlert-iconFilled': {
          fill: '#f44336'
        }
      },
      '& .MuiAlert-message': {
        color: '#f44336'
      },
      '& .MuiAlert-action': {
        color: '#f44336'
      }
    }
  }
}));

export default useStyles;
