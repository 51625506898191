const handleCommonsValues = (values) => {
  const { first_name, last_name, full_name, email, hear_about_us } = values;
  const updatedFields = {
    EMAIL: email,
    NAME: full_name,
    MMERGE8: first_name,
    MMERGE7: last_name,
    HEARUS: hear_about_us
  };
  return updatedFields;
};

const handleFields = (values, additionalFields) => {
  const commonFields = handleCommonsValues(values);
  const updatedFields = { ...commonFields, ...additionalFields };
  return updatedFields;
};

export const handleContentCreator = (fields, values) => {
  const { work_type, sub_work_type, engagement } = values;
  const additionalFields = {
    USE: work_type,
    CONTETYPE: sub_work_type,
    ENGAGEMENT: engagement
  };
  return handleFields(values, additionalFields);
};

export const handleBusinessOwner = (fields, values) => {
  const { work_type, sub_work_type, website } = values;
  const additionalFields = {
    USE: work_type,
    BUSTYPE: sub_work_type,
    WEBSITE: website
  };
  return handleFields(values, additionalFields);
};

export const handleMarketer = (fields, values) => {
  const { work_type, sub_work_type, role, other_role } = values;
  const additionalFields = {
    USE: work_type,
    MARKTYPE: sub_work_type,
    ROLE: role === 'other' ? 'Other' : role,
    OTHERROLE: role === 'other' ? other_role : undefined
  };
  return handleFields(values, additionalFields);
};

export const handleEducator = (fields, values) => {
  const { work_type, sub_work_type, role, other_role } = values;
  const additionalFields = {
    USE: work_type,
    COACHTYPE: sub_work_type,
    ROLE: role === 'other' ? 'Other' : role,
    OTHERROLE: role === 'other' ? other_role : undefined
  };
  return handleFields(values, additionalFields);
};

export const handleNonProfit = (fields, values) => {
  const { work_type, sub_work_type, role, other_role } = values;
  const additionalFields = {
    USE: work_type,
    NONPROTYPE: sub_work_type,
    ROLE: role === 'other' ? 'Other' : role,
    OTHERROLE: role === 'other' ? other_role : undefined
  };
  return handleFields(values, additionalFields);
};

export const handleOther = (fields, values) => {
  const { work_type, sub_work_type, role, other_role } = values;
  const additionalFields = {
    USE: work_type,
    OTHERJOB: sub_work_type,
    ROLE: role === 'other' ? 'Other' : role,
    OTHERROLE: role === 'other' ? other_role : undefined
  };
  return handleFields(values, additionalFields);
};
