import {
  handleContentCreator,
  handleBusinessOwner,
  handleMarketer,
  handleEducator,
  handleNonProfit,
  handleOther
} from '~/components/MailchimpSurvey/SignupSurvey/helpers/handleAnswers';

export const handleFieldSelection = (fields, values, category) => {
  switch (category) {
    case 'Content Creator / Influencer': {
      return handleContentCreator(fields, values);
    }
    case 'Business Owner': {
      return handleBusinessOwner(fields, values);
    }
    case 'Marketer': {
      return handleMarketer(fields, values);
    }
    case 'Educator / Coach': {
      return handleEducator(fields, values);
    }
    case 'Non-profit organization': {
      return handleNonProfit(fields, values);
    }
    default: {
      return handleOther(fields, values);
    }
  }
};
