import PropTypes from 'prop-types';
import React from 'react';
import SignupSurvey from '~/components/MailchimpSurvey/SignupSurvey/index.jsx';
import { useAuthentication } from '~/hooks/useAuthentication';
import CustomDialog from '../Dialog';
import useStyles from '~/components/MailchimpSurvey/SignupSurvey/styles/onboarding_styles';
import { useRouter } from 'next/router';

const UserProfessionJob = ({ forwardRef, onChange, hideCloseIcon }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const dialogRef = React.useRef(null);
  const { user } = useAuthentication();
  const router = useRouter();

  React.useEffect(() => {
    if (router.query.signupSurvey) {
      dialogRef && dialogRef.current && dialogRef.current.show();
    } else {
      dialogRef && dialogRef.current && dialogRef.current.hide();
    }
  }, [router.query.signupSurvey]);

  const handleEscape = () => {
    if (dialogRef && dialogRef.current) dialogRef.current.hide();
  };

  React.useImperativeHandle(forwardRef, () => ({
    show: () => {
      dialogRef && dialogRef.current && dialogRef.current.show();
      const url = new URL(window.location.href);
      url.searchParams.set('signupSurvey', true);
      window.history.pushState({ path: url.href }, '', url.href);
    },
    hide: () => {
      dialogRef && dialogRef.current && dialogRef.current.hide();
    }
  }));

  return (
    <CustomDialog
      forwardRef={dialogRef}
      fullWidth
      className={classes.root}
      maxWidth="100%"
      onEscapeKeyDown={handleEscape}
      useClose={!hideCloseIcon}
      isLoading={loading}
      onClose={handleEscape}
      loaderBackgroundColor="rgba(255, 255, 255, 15%)"
    >
      <SignupSurvey user={user} handleEscape={handleEscape} />
    </CustomDialog>
  );
};

UserProfessionJob.propTypes = {
  forwardRef: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  hideCloseIcon: PropTypes.bool
};

UserProfessionJob.defaultProps = {
  forwardRef: {},
  onChange: () => {},
  hideCloseIcon: false
};

export default UserProfessionJob;
