import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';
import { useAuthentication } from '~/hooks/useAuthentication';
const useStyles = (props) => {
  const { disabled } = props;
  return makeStyles((theme) => ({
    disabled: {
      backgroundColor: '#e0e0e0',
      color: 'rgba(0, 0, 0, 0.26)',
      '&:hover': {
        backgroundColor: '#e0e0e0',
        color: 'rgba(0, 0, 0, 0.26)'
      },
      '& .arrow::before': {
        borderColor: 'rgba(0, 0, 0, 0.26)',
        border: `1px solid red`
      },
      '& > *': {
        color: 'rgba(0, 0, 0, 0.26)'
      }
    },
    button: {
      '--primary-color': '#645bff',
      '--secondary-color': '#fff',
      '--hover-color': '#111',
      '--arrow-width': '10px',
      '--arrow-stroke': '2px',
      boxSizing: 'border-box',
      border: 0,
      borderRadius: '20px',
      transition: 'all 0.3s ease-in-out',
      color: 'var(--secondary-color)',
      padding: '1em 1.8em',
      background: 'var(--primary-color)',
      display: 'flex',
      alignItems: 'center',
      gap: '0.6em',
      fontWeight: 'bold',
      '&.disabled': {
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.26)',
        border: '1px solid rgba(0, 0, 0, 0.26)'
      },
      '&:hover': {
        backgroundColor: 'var(--hover-color)'
      },
      '& .arrow-wrapper': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&.disabled': {
          backgroundColor: 'transparent',
          color: 'rgba(0, 0, 0, 0.26)'
        }
      },
      '& .arrow': {
        marginTop: '1px',
        width: 'var(--arrow-width)',
        background: 'transparent',
        height: 'var(--arrow-stroke)',
        position: 'relative',
        transition: '0.2s',
        '&.disabled': {
          backgroundColor: 'transparent',
          color: 'rgba(0, 0, 0, 0.26)'
        }
      },
      '& .arrow::before': {
        content: '""',
        boxSizing: 'border-box',
        position: 'absolute',
        border: `var(--arrow-stroke) solid ${disabled ? 'rgba(0, 0, 0, 0.26)' : '#1976d2'}`,
        borderWidth: '0 var(--arrow-stroke) var(--arrow-stroke) 0',
        display: 'inline-block',
        top: '-3px',
        right: '3px',
        transition: '0.2s',
        padding: '3px',
        transform: 'rotate(-45deg)'
      },
      '&:hover .arrow': {
        background: 'var(--primary-color)'
      },
      '&:hover .arrow:before': {
        right: 0
      }
    }
  }))({ disabled });
};
import { handleFieldSelection } from '../../helpers/handleFieldSelection';
import CircularProgress from '@mui/material/CircularProgress';

const FinishButton = ({ disabled = false }) => {
  const classes = useStyles({ disabled });
  const { user } = useAuthentication();

  const { formValues, fields, setFields, setIsComplete, loading } = useOnboardingStore();

  const handleClick = () => {
    const newFormValues = {
      ...formValues,
      email: user.email,
      full_name: `${formValues.first_name} ${formValues.last_name}`
    };
    const newFields = handleFieldSelection(fields, newFormValues, newFormValues.work_type);
    setFields(newFields);
    setIsComplete(true);
  };

  return (
    <Button
      className={clsx(classes.button, disabled && 'disabled')}
      disabled={disabled || loading}
      onClick={handleClick}
    >
      Go to Dashboard
      {!loading && (
        <Box className={clsx('arrow-wrapper', disabled && 'disabled')}>
          <Box className={clsx('arrow', disabled && 'disabled')} />
        </Box>
      )}
      {loading && <CircularProgress size={20} />}
    </Button>
  );
};

export default FinishButton;
