import InputComponent from '~/components/MailchimpSurvey/SignupSurvey/components/InputComponent';
import MultiSelectionComponent from '~/components/MailchimpSurvey/SignupSurvey/components/MultiSelectionComponent';
import SingleSelectionComponent from '~/components/MailchimpSurvey/SignupSurvey/components/SingleSelectionComponent';
import formQuestions from '~/components/MailchimpSurvey/SignupSurvey/data/questions';

export const renderQuestionComponent = (questionIndex) => {
  switch (questionIndex) {
    case 0: // Pregunta 0: Nombre y apellido
      return <InputComponent questionData={formQuestions['first&last_name']} />;
    case 1: // Pregunta 1: Tipo de trabajo
      return (
        <MultiSelectionComponent
          label={formQuestions.work_type.label}
          placeholder={formQuestions.work_type.placeholder}
          next={formQuestions.work_type.next}
          prev={formQuestions.work_type.prev}
          options={formQuestions.work_type.options}
          writingOptions={['other']}
        />
      );
    case 2: // Pregunta 2: Rol laboral
      return (
        <SingleSelectionComponent
          label={formQuestions.user_role.label}
          placeholder={formQuestions.user_role.placeholder}
          next={formQuestions.user_role.next}
          prev={formQuestions.user_role.prev}
          options={formQuestions.user_role.options}
          storeKey={formQuestions.user_role.storeKey}
        />
      );
    case 3: // Pregunta 3: Participación en redes sociales
      return (
        <SingleSelectionComponent
          label={formQuestions.user_engagement.label}
          placeholder={formQuestions.user_engagement.placeholder}
          next={formQuestions.user_engagement.next}
          prev={formQuestions.user_engagement.prev}
          options={formQuestions.user_engagement.options}
          storeKey={formQuestions.user_engagement.storeKey}
        />
      );
    case 4: // Pregunta 5: Sitio web de la empresa
      return <InputComponent questionData={formQuestions.user_website} />;

    case 5: // Pregunta 6: ¿Cómo te enteraste de nosotros?
      return (
        <SingleSelectionComponent
          label={formQuestions.how_did_you_hear_about_us.label}
          placeholder={formQuestions.how_did_you_hear_about_us.placeholder}
          next={formQuestions.how_did_you_hear_about_us.next}
          prev={formQuestions.how_did_you_hear_about_us.prev}
          options={formQuestions.how_did_you_hear_about_us.options}
          storeKey={formQuestions.how_did_you_hear_about_us.storeKey}
        />
      );
    default:
      return null;
  }
};
