import { Box, Container } from '@material-ui/core';
import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useMailChimpForm } from 'use-mailchimp-form';
import ActionAlert from '~/components/Alert';
import useSetProfile from '~/components/MailchimpSurvey/SignupSurvey/hooks/useSetProfile';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';
import useStyles from '~/components/MailchimpSurvey/SignupSurvey/styles/onboarding_styles';
import useMailchimpErrorResponse from '~/hooks/useMailchimErrorResponse';
import BackButton from './components/BackButton';
import { renderQuestionComponent } from './helpers/renderQuestionComponent';
import useActiveImageUpdate from './hooks/useActiveImageUpdate';
import { useOnboardingInitialization } from './hooks/useInitializeStore';
import useHandleSubmit from './hooks/useHandleSubmit';
import CustomStepper from './components/Stepper';
import { useHandleStepper } from './hooks/useHandleStepper';

const MAILCHIMP_FORM_URL =
  'https://front10.us17.list-manage.com/subscribe/post?u=8adc856ac0510bf2f3f3eddac&amp;id=a11bc20fb9&amp;f_id=006ebde0f0';

const SignupSurvey = ({ user, handleEscape }) => {
  const classes = useStyles();
  const {
    activeImage,
    activeQuestionIndex,
    prevQuestionIndex,
    nextQuestionIndex,
    displayMessage,
    setDisplayMessage,
    formValues,
    fields,
    isComplete,
    error,
    setError,
    setLoading
  } = useOnboardingStore();
  const { loading, success, message, handleSubmit } = useMailChimpForm(MAILCHIMP_FORM_URL);
  const [profile, setProfile] = React.useState(null);
  const dialogRef = React.useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  useOnboardingInitialization(nextQuestionIndex, prevQuestionIndex, Object.keys(formValues));
  useActiveImageUpdate();
  useHandleStepper();
  useMailchimpErrorResponse(error, message, setDisplayMessage, handleEscape);
  useSetProfile(profile, setProfile, dialogRef, handleEscape);
  useHandleSubmit(
    isComplete,
    success,
    loading,
    setLoading,
    setDisplayMessage,
    handleSubmit,
    fields
  );
  return (
    <Box className={clsx(classes.root)}>
      <Container className={classes.container} maxWidth="lg">
        {error === null && <CustomStepper />}
        {prevQuestionIndex !== null && (
          <Box className={classes.backButtonContainer}>
            <BackButton />
          </Box>
        )}
        <Box className={clsx(classes.formContainer, isMobile && 'mobile')}>
          {renderQuestionComponent(activeQuestionIndex)}
        </Box>
        {!isMobile && (
          <Box className={classes.pictureContainer}>
            <Image src={activeImage} width={500} height={500} alt="hero image" />
          </Box>
        )}
        {error !== null && (
          <ActionAlert
            className={clsx(classes.alert, error && 'error', !error && 'success')}
            open={error !== null}
            onClose={() => setError(null)}
            type={error ? 'error' : 'success'}
            message={error}
          />
        )}
      </Container>
    </Box>
  );
};

export default SignupSurvey;
