import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import NextButton from '../NextButton';
import Box from '@material-ui/core/Box';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';
import { useOnboardingInitialization } from '../../hooks/useInitializeStore';
import FinishButton from '~/components/MailchimpSurvey/SignupSurvey/components/FinishButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    gap: '80px',
    flexDirection: 'column',
    width: '100%',
    margin: '50px auto',
    borderRadius: '12px',
    padding: '30px 20px'
  },

  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    height: '50%'
  },
  searchLabel: {
    marginBottom: theme.spacing(1),
    fontSize: '30px',
    fontWeight: 500,
    textAlign: 'center',
    '&.disabled': {
      color: '#ccc',
      cursor: 'not-allowed'
    },
    '@media (max-width: 600px)': {
      fontSize: '20px'
    }
  },
  searchIcon: {
    marginRight: theme.spacing(1)
  },
  selectInput: {
    width: '100%',
    maxWidth: '400px',
    textAlign: 'center',
    cursor: 'pointer'
  }
}));

const SingleSelectionComponent = ({ label, placeholder, options, next, prev, storeKey }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    activeQuestionIndex,
    etPrevQuestionIndex,
    setNextQuestionIndex,
    formValues,
    setFormValues
  } = useOnboardingStore();
  const allowWrite = selectedOption?.value === 'other';
  const [value, setValue] = useState('');

  const isDisabled = selectedOption === null || (allowWrite && value === '');

  React.useEffect(() => {
    setSelectedOption(null);
  }, [activeQuestionIndex]);

  useOnboardingInitialization(
    next,
    prev,
    Object.keys({
      role: '',
      other_role: '',
      website: '',
      engagement: ''
    })
  );

  const handleSelectedOption = (option) => {
    setSelectedOption(option);
    const newFormValues = { ...formValues };
    newFormValues[storeKey] = option.value;
    setFormValues(newFormValues);
    handleMenuClose();
  };

  const handleWrite = (event) => {
    setValue(event.target.value);
    setFormValues({
      ...formValues,
      other_role: event.target.value
    });
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <Typography className={classes.searchLabel}>{label}</Typography>
        <TextField
          value={formValues[storeKey]}
          onChange={setSelectedOption}
          placeholder={placeholder}
          className={classes.selectInput}
          disabled={!options.length}
          onClick={handleMenuOpen}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            )
          }}
        />
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                handleSelectedOption(option);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>

      {allowWrite && (
        <div className={classes.searchContainer}>
          <TextField
            className={classes.selectInput}
            value={value}
            onChange={handleWrite}
            placeholder={'Write your answer here'}
          />
        </div>
      )}

      {next !== null && (
        <Box display="flex" justifyContent="center" width="100%">
          <NextButton disabled={isDisabled} />
        </Box>
      )}
      {next === null && (
        <Box display="flex" justifyContent="center" width="100%">
          <FinishButton disabled={isDisabled} />
        </Box>
      )}
    </div>
  );
};

export default SingleSelectionComponent;
