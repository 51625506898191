import { useEffect } from 'react';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';

const indexAndSteps = [
  { index: 0, step: 0 },
  { index: 1, step: 1 },
  { index: 2, step: 2 },
  { index: 3, step: 2 },
  { index: 4, step: 2 },
  { index: 5, step: 3 }
];

const useHandleStepper = () => {
  const { activeQuestionIndex, setActiveStep } = useOnboardingStore();

  useEffect(() => {
    const step = indexAndSteps.find((item) => item.index === activeQuestionIndex);
    if (step) {
      setActiveStep(step.step);
    }
  }, [activeQuestionIndex, setActiveStep]);
};

export { useHandleStepper };
