import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    height: '3em',
    width: 'fit-content',
    padding: '0 1.5em',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#eeeeee4b',
    borderRadius: '12px',
    letterSpacing: '1px',
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    border: '1px solid #08AEEA',
    color: '#08AEEA',
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
      background: '#eeeeee4b',
      boxShadow: '0px 0px 5px 0px #08AEEA'
    },
    '&.disabled': {
      background: '#eeeeee4b',
      boxShadow: '0px 0px 5px 0px #eeeeee4b',
      border: '1px solid rgb(204, 204, 204)',
      color: '#ccc',
      cursor: 'not-allowed'
    }
  },
  svgIcon: {
    marginLeft: '5px',
    fontSize: '20px',
    transition: 'all 0.3s ease-in'
  },
  hoverSvgIcon: {
    fontSize: '1.2em',
    transform: 'translateX(5px)'
  }
}));

const NextButton = ({ handleNext, disabled }) => {
  const classes = useStyles();
  const { setActiveQuestionIndex, nextQuestionIndex, activeQuestionIndex, setPrevQuestionIndex } =
    useOnboardingStore();

  const handleClick = () => {
    if (handleNext) {
      handleNext();
      setPrevQuestionIndex(activeQuestionIndex);
      setActiveQuestionIndex(nextQuestionIndex);
    } else {
      setActiveQuestionIndex(nextQuestionIndex);
    }
  };

  return (
    <Button
      className={clsx(classes.button, disabled && 'disabled')}
      disabled={nextQuestionIndex === null || disabled}
      onClick={handleClick}
    >
      <span>Next Question</span>
      <SvgIcon component={KeyboardArrowRightIcon} className={classes.svgIcon} />
    </Button>
  );
};

export default NextButton;
