import { useEffect } from 'react';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';
import formQuestions from '~/components/MailchimpSurvey/SignupSurvey/data/questions';

const useActiveImageUpdate = () => {
  const { activeQuestionIndex, setActiveImage, activeImage } = useOnboardingStore();

  useEffect(() => {
    let image = null;

    switch (activeQuestionIndex) {
      case 0:
        image = formQuestions['first&last_name'].image;
        break;
      case 1:
        image = formQuestions.work_type.image;
        break;
      case 2:
        image = formQuestions.user_role.image;
        break;
      case 3:
        image = formQuestions.user_engagement.image;
        break;
      case 4:
        image = formQuestions.user_website.image;
        break;
      case 5:
        image = formQuestions.how_did_you_hear_about_us.image;
      default:
        break;
    }
    setActiveImage(image);
  }, [activeQuestionIndex, setActiveImage]);
};

export default useActiveImageUpdate;
