// En este objeto se definen las preguntas del formulario
// El orden de las preguntas es el siguiente:

// Pregunta 0: Nombre y apellido
// Esta pregunta solicita al usuario que ingrese su nombre y apellido
// Pregunta anterior: Ninguna
// Pregunta siguiente: Tipo de trabajo

// Pregunta 1: Tipo de trabajo
// Esta pregunta indaga sobre el tipo de trabajo que realiza el usuario
// Pregunta anterior: Nombre y apellido
// Pregunta siguiente: Cada tipo de trabajo tiene una pregunta diferente (ver abajo)
/* 
    2.1: Content creator (creador de contenido) -> Pregunta 3
    2.2: Business owner (dueño de negocio) -> Pregunta 4
    Todos los demás -> Pregunta 2 (Rol laboral)
*/

// Pregunta 2: Rol laboral
// Esta pregunta busca conocer el rol laboral del usuario en su empresa o proyecto
// Pregunta anterior: Tipo de trabajo
// Pregunta siguiente: No tiene

// Pregunta 3: Participación en redes sociales
// Esta pregunta indaga sobre la participación del usuario en plataformas de redes sociales
// Pregunta anterior: Tipo de trabajo (Content creator)
// Pregunta siguiente: No tiene

// Pregunta 4: Sitio web de la empresa
// Esta pregunta busca conocer el sitio web de la empresa del usuario
// Pregunta anterior: Tipo de trabajo (Business owner)
// Pregunta siguiente: No tiene

const formQuestions = {
  'first&last_name': {
    id: 0,
    label: 'What is your name?',
    next: 1,
    prev: null,
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1689101133/marketing/onboarding/02.jpg',
    fields: {
      first_name: 'MMERGE8',
      last_name: 'MMERGE7',
      full_name: 'NAME',
      email: 'EMAIL'
    },
    inputs: [
      {
        label: 'First Name',
        value: 'first_name',
        placeholder: 'First Name',
        validation: {
          required: true,
          minLength: 2,
          maxLength: 50,
          capitalize: true,
          regex: /^\s*(?!.*\d)[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*\s*$/,
          error: 'The name cannot contain numbers or special characters'
        },
        type: 'text',
        storeValue: 'first_name'
      },
      {
        label: 'Last Name',
        value: 'last_name',
        placeholder: 'Last Name',
        validation: {
          required: true,
          minLength: 2,
          maxLength: 50,
          capitalize: true,
          regex: /^\s*(?!.*\d)[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*\s*$/,
          error: 'The last name cannot contain numbers or special characters'
        },
        type: 'text',
        storeValue: 'last_name'
      }
    ]
  },

  work_type: {
    id: 1,
    next: 2,
    prev: 0,
    label: 'What kind of work do you do?',
    storeValue: 'work_type',
    placeholder: 'Content creator, Marketer, Business owner, etc.',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1689101142/marketing/onboarding/07.jpg',
    options: [
      {
        next: 2,
        prev: 0,
        value: 'marketer',
        label: 'Marketer',
        subOptions: [
          { value: 'Agency Marketer', label: 'Agency Marketer' },
          { value: 'Freelance Marketer', label: 'Freelance Marketer' },
          { value: 'In-house Marketer', label: 'In-house Marketer' },
          { value: 'Marketer & business owner', label: 'Marketer & business owner' },
          { value: 'other', label: 'Other' }
        ]
      },
      {
        next: 4,
        prev: 0,
        value: 'businessOwner',
        label: 'Business Owner',
        subOptions: [
          { value: 'Retail/Online Store', label: 'Retail/Online Store' },
          { value: 'Restaurant/Cafe', label: 'Restaurant/Cafe' },
          { value: 'Health & Fitness', label: 'Health & Fitness' },
          { value: 'Professional Services', label: 'Professional Services' },
          { value: 'Real State', label: 'Real State' },
          { value: 'other', label: 'Other' }
        ]
      },
      {
        next: 3,
        prev: 0,
        value: 'contentCreator',
        label: 'Content Creator / Influencer',
        subOptions: [
          {
            value: 'Fitness & Wellness',
            label: 'Fitness & Wellness'
          },
          { value: 'Beauty & Fashion', label: 'Beauty & Fashion' },
          { value: 'Gaming', label: 'Gaming' },
          { value: 'Food & Cooking', label: 'Food & Cooking' },
          { value: 'Travel', label: 'Travel' },
          { value: 'other', label: 'Other' }
        ]
      },
      {
        next: 2,
        prev: 0,
        value: 'educator',
        label: 'Educator / Coach',
        subOptions: [
          { value: 'School Teacher', label: 'School Teacher' },
          { value: 'Online Tutor', label: 'Online Tutor' },
          { value: 'Fitness Coach', label: 'Fitness Coach' },
          { value: 'Lifestyle Coach', label: 'Lifestyle Coach' },
          { value: 'Business Consultant', label: 'Business Consultant' },
          { value: 'other', label: 'Other' }
        ]
      },
      {
        next: 2,
        prev: 0,
        value: 'non-profit',
        label: 'Non-profit organization',
        subOptions: [
          { value: 'Humanitarian Aid Organization', label: 'Humanitarian Aid Organization' },
          { value: 'Environmental Non-profit', label: 'Environmental Non-profit' },
          { value: 'Educational Charity', label: 'Educational Charity' },
          { value: 'Health-related Non-profit', label: 'Health-related Non-profit' },
          { value: 'Community Outreach', label: 'Community Outreach' },
          { value: 'other', label: 'Other' }
        ]
      },
      {
        next: 2,
        prev: 0,
        value: 'other',
        label: 'Other (Please specify)'
      }
    ]
  },
  user_role: {
    id: 2,
    label: 'What is your job role?',
    next: 5,
    prev: 1,
    placeholder: 'Owner, team manager, freelancer, etc.',
    storeKey: 'role',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1689101144/marketing/onboarding/04.jpg',
    options: [
      {
        value: 'Owner / Executive',
        label: 'Owner / Executive',
        next: 5,
        prev: 1
      },
      { value: 'Team Manager', label: 'Team Manager' },
      { value: 'Team Member', label: 'Team Member' },
      {
        value: 'Solo-Entrepreneur / Freelancer',
        label: 'Solo-Entrepreneur / Freelancer'
      },
      { value: 'other', label: 'Other' }
    ]
  },
  user_engagement: {
    id: 3,
    label: 'Typical engagement on your most active social media platform?',
    next: 5,
    prev: 1,
    placeholder: '1K - 10K followers',
    storeKey: 'engagement',
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1689101133/marketing/onboarding/05.jpg',
    options: [
      { value: 'I’m just getting started!', label: 'I’m just getting started!' },
      { value: '1K - 10K followers', label: '1K - 10K followers' },
      { value: '10K - 50K followers', label: '10K - 50K followers' },
      { value: '50K - 500K followers', label: '50K - 500K followers' },
      { value: '500K to 1 million followers', label: '500K to 1 million followers' },
      { value: '1 million+  followers', label: '1 million+  followers' }
    ]
  },
  user_website: {
    id: 4,
    label: 'What is your company’s website?',
    next: 5,
    prev: 1,
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1689101134/marketing/onboarding/06.jpg',
    inputs: [
      {
        label: 'Website',
        value: 'website',
        placeholder: 'www.example.com',
        validations: {
          required: true,
          maxLength: 50,
          regex: /^(https?:\/\/)?(www\.[\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
          error: 'Please enter a valid website. e.g: www.example.com'
        },
        type: 'text',
        storeValue: 'user_website'
      }
    ]
  },
  how_did_you_hear_about_us: {
    id: 5,
    label: 'Where did you hear about us?',
    next: null,
    prev: 1,
    image:
      'https://res.cloudinary.com/dakp804eh/image/upload/v1689101138/marketing/onboarding/03.jpg',
    placeholder: 'Google, Facebook, Instagram, etc.',
    storeKey: 'hear_about_us',
    options: [
      {
        value: 'Search engine results (e.g., Google, Bing)',
        label: 'Search engine results (e.g., Google, Bing)'
      },
      { value: 'AI tools directory', label: 'AI tools directory' },
      { value: 'From a Youtuber', label: 'From a Youtuber' },
      { value: 'Blog or newsletter', label: 'Blog or newsletter' },
      { value: 'While scrolling on social media', label: 'While scrolling on social media' },
      { value: 'Recommendation from a colleague', label: 'Recommendation from a colleague' },
      { value: 'Industry event', label: 'Industry event' }
    ]
  }
};

export default formQuestions;
