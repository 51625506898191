import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    height: '3em',
    width: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    letterSpacing: '1px',
    transition: 'all 0.2s linear',
    cursor: 'pointer',
    border: 'none',
    background: '#fff',
    '&:hover': {
      background: '#fff',
      gap: '0.5em'
    }
  },
  svgIcon: {
    marginRight: '5px',
    marginLeft: '5px',
    fontSize: '20px',
    transition: 'all 0.4s ease-in'
  },
  hoverSvgIcon: {
    fontSize: '1.2em',
    transform: 'translateX(-5px)',
    transition: 'all 0.4s ease-in',
    '&:hover': {
      transform: 'translateX(-5px)'
    }
  }
}));

const BackButton = () => {
  const classes = useStyles();
  const { setActiveQuestionIndex, prevQuestionIndex } = useOnboardingStore();

  const handleBack = () => {
    setActiveQuestionIndex(prevQuestionIndex);
  };

  return (
    <Button className={classes.button} onClick={handleBack}>
      <SvgIcon component={KeyboardArrowLeftIcon} className={classes.svgIcon} />
      <span>Back</span>
    </Button>
  );
};

export default BackButton;
