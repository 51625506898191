import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    position: 'relative',
    width: '100%',
    margin: '30px auto'
  },
  inputBox: {
    fontSize: '16px',
    padding: '10px 0',
    border: 'none',
    borderBottom: '2px solid #ccc',
    color: '#08AEEA',
    width: '100%',
    backgroundColor: 'transparent',
    transition: 'border-color 0.3s ease-in-out',
    '&:focus': {
      borderColor: '#08AEEA',
      outline: 'none'
    }
  },
  underline: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '2px',
    backgroundColor: '#08AEEA',
    transform: 'scaleX(0)',
    transition: 'transform 0.3s ease-in-out',
    '.inputBox:focus + &': {
      transform: 'scaleX(1)'
    }
  },
  errorText: {
    color: 'red',
    fontSize: '12px',
    marginTop: '5px',
    textAlign: 'left'
  }
}));

const InputComponent = ({ value, onChange, placeholder, error }) => {
  const classes = useStyles();

  return (
    <div className={classes.inputWrapper}>
      <input
        value={value}
        onChange={onChange}
        className={classes.inputBox}
        type="text"
        placeholder={placeholder}
      />
      <span className={classes.underline}></span>
      {error && value !== '' && <div className={classes.errorText}>{error}</div>}
    </div>
  );
};

export default InputComponent;
