import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import NextButton from '../NextButton';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';
import { useOnboardingInitialization } from '../../hooks/useInitializeStore';
import FinishButton from '~/components/MailchimpSurvey/SignupSurvey/components/FinishButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    gap: '80px',
    flexDirection: 'column',
    width: '100%',
    margin: '50px auto',
    borderRadius: '12px',
    padding: '30px 20px'
  },

  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    height: '50%'
  },
  searchLabel: {
    marginBottom: theme.spacing(1),
    fontSize: '30px',
    fontWeight: 500,
    textAlign: 'center',
    '&.disabled': {
      color: '#ccc',
      cursor: 'not-allowed'
    },
    '@media (max-width: 600px)': {
      fontSize: '20px'
    }
  },
  searchIcon: {
    marginRight: theme.spacing(1)
  },
  selectInput: {
    width: '100%',
    maxWidth: '400px',
    textAlign: 'center',
    cursor: 'pointer'
  }
}));

const MultiSelectionComponent = ({
  label,
  placeholder,
  options,
  next,
  prev,
  writingOptions = []
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setPrevQuestionIndex, setNextQuestionIndex, formValues, setFormValues, displayMessage } =
    useOnboardingStore();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState('');
  const [subOptions, setSubOptions] = useState([]);
  const [allowWrite, setAllowWrite] = useState(false);
  const [writingValue, setWritingValue] = useState('');

  const isDisabled =
    selectedOption === null ||
    selectedOption === undefined ||
    selectedOption === '' ||
    (allowWrite && writingValue === '') ||
    (selectedSubOption === '' && subOptions.length > 0) ||
    displayMessage !== '';

  React.useEffect(() => {
    setNextQuestionIndex(next);
    setPrevQuestionIndex(prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnboardingInitialization(
    next,
    prev,
    Object.keys({
      work_type: '',
      sub_work_type: '',
      role: '',
      other_role: '',
      website: '',
      engagement: ''
    })
  );

  React.useEffect(() => {
    if (selectedOption) {
      setAllowWrite(writingOptions.includes(selectedOption?.value));
      setNextQuestionIndex(selectedOption?.next);
      setPrevQuestionIndex(selectedOption?.prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const handleSelectedOption = (option) => {
    setSelectedOption(option);
    setFormValues({
      ...formValues,
      work_type: option.label,
      sub_work_type: ''
    });
    setNextQuestionIndex(option.next);
    setPrevQuestionIndex(option.prev);
    setSubOptions(option.subOptions || []);
    handleMenuClose();
  };

  const handleWritingValue = (event) => {
    const value = event.target.value;
    setWritingValue(value);
    setFormValues({
      ...formValues,
      sub_work_type: value
    });
  };
  const handleSubOption = (event) => {
    setSelectedSubOption(event.target.value);
    setFormValues({ ...formValues, sub_work_type: event.target.value });
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <Typography className={classes.searchLabel}>{label}</Typography>
        <TextField
          value={selectedOption?.label || ''}
          onChange={setSelectedOption}
          placeholder={placeholder}
          className={classes.selectInput}
          disabled={!options.length}
          onClick={handleMenuOpen}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            )
          }}
        />
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                handleSelectedOption(option);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div className={classes.searchContainer}>
        <Typography className={clsx(classes.searchLabel, selectedOption === null && 'disabled')}>
          {'Sub-function or niche'}
        </Typography>
        {!allowWrite && (
          <Select
            value={selectedSubOption}
            onChange={handleSubOption}
            className={classes.selectInput}
            disabled={subOptions.length === 0 || selectedOption === null}
          >
            {subOptions.map((field) => (
              <MenuItem key={field.value} value={field.value}>
                {field.label}
              </MenuItem>
            ))}
          </Select>
        )}
        {allowWrite && (
          <TextField
            value={writingValue}
            onChange={handleWritingValue}
            placeholder={'Type your job title'}
            className={classes.selectInput}
          />
        )}
      </div>
      <Box display="flex" justifyContent="center" width="100%">
        {next !== null && <NextButton disabled={isDisabled} />}
        {next === null && <FinishButton disabled={isDisabled} />}
      </Box>
    </div>
  );
};

export default MultiSelectionComponent;
