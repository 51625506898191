import React from 'react';
import { useRouter } from 'next/router';

const useHandleSubmit = (
  isComplete,
  success,
  loading,
  setLoading,
  setDisplayMessage,
  handleSubmit,
  fields
) => {
  const router = useRouter();

  React.useEffect(() => {
    if (loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loading, setLoading]);

  React.useEffect(() => {
    if (isComplete) {
      handleSubmit(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplete]);

  React.useEffect(() => {
    if (success === true) {
      setDisplayMessage('Thank you for subscribing!');
      setTimeout(() => {
        router.push('/dashboard');
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);
};

export default useHandleSubmit;
