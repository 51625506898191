import React from 'react';

const useMailchimpErrorResponse = (error, message, setDisplayMessage, handleEscape) => {
  return React.useEffect(() => {
    if (error) {
      if (message.includes('timed out')) {
        setDisplayMessage('Please check your internet connection and try again.');
      } else if (message.includes('already subscribed')) {
        setDisplayMessage('You are already subscribed to this form.');
        setTimeout(() => {
          handleEscape();
        }, 2000);
      } else if (message.includes('invalid email address')) {
        setDisplayMessage('Please enter a valid email address.');
      } else if (message.includes('has too many recent signup requests')) {
        setDisplayMessage('You have already submitted this form. Please try again later.');
        setTimeout(() => {
          handleEscape();
        }, 2000);
      } else {
        setDisplayMessage('Something went wrong. Please try again.');
      }
    } else {
      if (message.includes('Thank you')) {
        setTimeout(() => {
          handleEscape();
        }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export default useMailchimpErrorResponse;
