import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import CustomInput from '~/components/MailchimpSurvey/SignupSurvey/components/CustomInput';
import NextButton from '~/components/MailchimpSurvey/SignupSurvey/components/NextButton';
import FinishButton from '~/components/MailchimpSurvey/SignupSurvey/components/FinishButton';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';
import PropTypes from 'prop-types';
import { useOnboardingInitialization } from '~/components/MailchimpSurvey/SignupSurvey/hooks/useInitializeStore';

const InputComponent = ({ questionData }) => {
  const {
    setNextQuestionIndex,
    setPrevQuestionIndex,
    displayMessage,
    setDisplayMessage,
    formValues,
    setFormValues,
    error,
    setError
  } = useOnboardingStore();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [regexError, setRegexError] = React.useState(false);

  useOnboardingInitialization(questionData.next, questionData.prev, questionData.inputs);

  useEffect(() => {
    setNextQuestionIndex(questionData.next);
    setPrevQuestionIndex(questionData.prev);
  }, [questionData.next, questionData.prev, setNextQuestionIndex, setPrevQuestionIndex]);

  const isDisabled = questionData.inputs.some((input) => {
    const { value } = input;
    if (displayMessage !== '') return true;
    if (formValues[value] === '') return true;
    if (error !== null) return true;
    if (regexError !== '') return true;
    return false;
  });

  const capitalizeWords = (value) => {
    const words = value.split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
  };

  const validateRegex = (value, regex) => {
    const regexPattern = new RegExp(regex);
    return regexPattern.test(value);
  };

  return (
    <Box width={'100%'} height={'100%'}>
      <Typography
        variant="h4"
        component="h4"
        gutterBottom
        style={{
          marginTop: isMobile ? '20px' : '0px',
          marginBottom: isMobile ? '20px' : '0px',
          textAlign: 'center'
        }}
      >
        {questionData.label}
      </Typography>
      {questionData.inputs.map((input, index) => (
        <Box key={index} style={{ marginTop: '20px' }}>
          <CustomInput
            value={formValues[input.value]}
            onChange={(e) => {
              const { value } = e.target;
              const isValid = validateRegex(value, input.validation?.regex);
              if (!isValid) {
                setRegexError(input.validation?.error);
                setError(input.validation?.error);
              } else {
                setRegexError('');
                setError(null);
                setDisplayMessage('');
              }
              const capitalizedValue = capitalizeWords(value);
              setFormValues({
                ...formValues,
                [input.value]: input.validation?.capitalize ? capitalizedValue : value
              });
            }}
            placeholder={input.placeholder}
          />
        </Box>
      ))}
      <Box
        style={{
          marginTop: '100px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {questionData.next !== null && <NextButton disabled={isDisabled} />}
        {questionData.next === null && <FinishButton disabled={isDisabled} />}
      </Box>
    </Box>
  );
};

InputComponent.propTypes = {
  user: PropTypes.object,
  questionData: PropTypes.object
};

export default InputComponent;
