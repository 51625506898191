import { create } from 'zustand';

const useOnboardingStore = create((set) => ({
  activeImage: '',
  activeQuestionIndex: 0,
  nextQuestionIndex: 1,
  prevQuestionIndex: null,
  isComplete: false,
  activeStep: 0,
  fields: {},
  formValues: {
    first_name: '',
    last_name: '',
    work_type: '',
    sub_work_type: '',
    role: '',
    other_role: '',
    website: '',
    engagement: '',
    hear_about_us: ''
  },
  loading: false,
  error: null,
  success: false,
  displayMessage: '',
  setActiveImage: (image) => set(() => ({ activeImage: image })),
  setActiveQuestionIndex: (index) => set(() => ({ activeQuestionIndex: index })),
  setNextQuestionIndex: (index) => set(() => ({ nextQuestionIndex: index })),
  setPrevQuestionIndex: (index) => set(() => ({ prevQuestionIndex: index })),
  setIsComplete: (isComplete) => set(() => ({ isComplete: isComplete })),
  setActiveStep: (step) => set(() => ({ activeStep: step })),
  setFields: (fields) => set(() => ({ fields: fields })),
  setFormValues: (values) => set(() => ({ formValues: values })),
  setLoading: (isLoading) => set(() => ({ loading: isLoading })),
  setError: (errorMessage) => set(() => ({ error: errorMessage })),
  setSuccess: (isSuccess) => set(() => ({ success: isSuccess })),
  setDisplayMessage: (message) =>
    set(() => ({
      displayMessage: message
    }))
}));

export { useOnboardingStore };
