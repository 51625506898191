import { useEffect } from 'react';
import { useOnboardingStore } from '~/components/MailchimpSurvey/SignupSurvey/store/store';

const useOnboardingInitialization = (next, prev, keys) => {
  const { setNextQuestionIndex, setPrevQuestionIndex, setFormValues, formValues } =
    useOnboardingStore();

  useEffect(() => {
    setNextQuestionIndex(next);
    setPrevQuestionIndex(prev);
    setFormValues({
      ...formValues,
      ...keys.reduce((acc, key) => ({ ...acc, [key]: '' }), {})
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNextQuestionIndex, setPrevQuestionIndex, setFormValues]);
};

export { useOnboardingInitialization };
